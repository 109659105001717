import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dev',
  templateUrl: './dev.component.html',
  styleUrls: [
    './dev.component.scss',
    '../../assets/style/base-detail.component.scss',
  ],
})
export class DevComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
