<div class="mat-elevation-z8 basecanvas">
  <div class="section group">
    <div class="col span_2_of_2">
      <h1 class="subtitle">{{ gladia.Name }}</h1>
      <hr>
    </div>
  </div>

  <div class="section group">
    <div class="col span_1_of_2" style="text-align: center;">
      <img class="gdimg" src="assets/Gladia/{{ gladia.ID }}.png" />
    </div>

    <div class="col span_1_of_2">
      <div class="divTable">
        <div class="divTableRow">
          <div class="divTableCell cell-tc40 font-weight-bold">공격력</div>
          <div class="divTableCell cell-tc60">{{ gladia.Atk }}</div>
        </div>
      </div>
      <hr class="hr90" />

      <div class="divTable">
        <div class="divTableRow">
          <div class="divTableCell cell-tc40 font-weight-bold">방어력</div>
          <div class="divTableCell cell-tc60">{{ gladia.Def }}</div>
        </div>
      </div>
      <hr class="hr90" />

      <div class="divTable">
        <div class="divTableRow">
          <div class="divTableCell cell-tc40 font-weight-bold">체력</div>
          <div class="divTableCell cell-tc60">{{ gladia.Hp }}</div>
        </div>
      </div>
      <hr class="hr90" />

      <div class="divTable">
        <div class="divTableRow">
          <div class="divTableCell cell-tc40 font-weight-bold">스킬위력</div>
          <div class="divTableCell cell-tc60">{{ gladia.Skill }}</div>
        </div>
      </div>
      <hr class="hr90" />

      <div class="divTable">
        <div class="divTableRow">
          <div class="divTableCell cell-tc40 font-weight-bold">착용 레벨</div>
          <div class="divTableCell cell-tc60">{{ gladia.ReqLv }}</div>
        </div>
      </div>
      <hr class="hr90" />

      <div class="divTable">
        <div class="divTableRow">
          <div class="divTableCell cell-tc40 font-weight-bold">가치</div>
          <div class="divTableCell cell-tc60">{{ gladia.Value }}</div>
        </div>
      </div>
      <hr class="hr90" />

      <div class="divTable">
        <div class="divTableRow">
          <div class="divTableCell cell-tc40 font-weight-bold">해제비용</div>
          <div class="divTableCell cell-tc60">
            <img class="inline15" src="assets/Etc/nyang.png" />
            {{ gladia.Cost }}
          </div>
        </div>
      </div>
      <hr class="hr90" />

      <ng-container *ngIf="gladia.Speed != ''">
        <div class="divTable">
          <div class="divTableRow">
            <div class="divTableCell cell-tc40 font-weight-bold">이동속도</div>
            <div class="divTableCell cell-tc60">{{ gladia.Speed }}</div>
          </div>
        </div>
        <hr class="hr90" />
      </ng-container>
      </div>

  <div class="section group">
    <div class="col span_2_of_2">
      <h2 class="subtitle">글라디아 대사</h2>
      <hr>
      <div class="divTable table-stripped">
        <ng-container *ngFor="let data of gladiaDialog">
          <div class="divTableRow">
            <div class="divTableCell cell-tc20">
              <span class="font-weight-bold">{{
                dialogType[data["type"]]
              }}</span>
            </div>
            <div
              class="divTableCell cell-tc85"
              style="text-align: left; white-space: pre-line"
            >
              <span>{{ data["text"] }}</span>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="section group">
    <div class="col span_2_of_2">
      <h2 class="subtitle">속성 저항</h2>
      <hr>
      <div class="divTable" style="table-layout: fixed;">
        <div class="divTableRow">
          <div
            class="divTableCell"
            *ngFor="let res of resistAtkType"
            style="width: 100%;"
          >
            <img class="inline15" src="assets/Etc/{{ res }}.png" />
          </div>
        </div>
      </div>

      <div class="divTable" style="table-layout: fixed;">
        <div class="divTableRow">
          <div
            class="divTableCell"
            *ngFor="let res of resistAtkType"
            style="width: 100%;"
          >
            {{ getGladiaAttr(res) }}
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="section group">
    <div class="col span_1_of_2" style="white-space: pre-wrap; word-break: keep-all;">
      <h2 class="subtitle">고유 효과</h2>
      <hr>
        <div class="divTable">
          <div class="divTableRow" *ngFor="let x of getDescription()">
            <div class="divTableCell cell-listpadding" style="text-align: left;">
              • {{x}}
            </div>
          </div>
        </div>
    </div>
    <div class="col span_1_of_2">
      <h2 class="subtitle">연관 글라디아</h2>
      <hr>

      <ng-container *ngIf="gladia.Rel !== ''">
        <div class="divTable" style="table-layout: fixed;">
          <div class="divTableRow">
            <div
              class="divTableCell"
              *ngFor="let d of getRelative(gladia.Rel)"
              style="width: 100%;"
            >
              <a routerLink="/gladia/{{ d }}">
                <img
                  class="inline40"
                  src="assets/GladiaThumbnails/{{ d }}.jpg"
                />
              </a>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <div class="section group">
    <div class="col span_1_of_2">
      <h2 class="subtitle">드랍 고치</h2>
      <hr>
      <div class="divTable" *ngIf="gladia.Drop">
        <ng-container *ngFor="let x of getCocoon(gladia.Drop)">
          <div class="divTableRow">
            <div class="divTableCell cell-tc30">
              <img
                class="inline40"
                src="assets/Cocoon/{{ x[0] | underbarEliminator }}.jpg"
              />
            </div>
            <div class="divTableCell cell-tc70">
              <a class="highlight" routerLink="/cocoon/{{ x[0] }}">{{
                x[1]
              }}</a>
            </div>
          </div>
        </ng-container>
      </div>
    </div>

    <div class="col span_1_of_2" *ngIf="gladia.PromotionMaterialsFull">
      <h2 class="subtitle">진화재료</h2>
      <hr class="hr90" />
      <div class="divTable">
        <ng-container
          *ngFor="let x of getFixedItem(gladia.PromotionMaterialsFull)"
        >
          <div class="divTableRow">
            <div class="divTableCell cell-tc30">
              <img
                class="inline40"
                src="assets/ItemsMaterials/{{ x[0] | underbarEliminator }}.jpg"
              />
            </div>
            <div class="divTableCell cell-tc40">
              <a class="highlight" routerLink="/materials/{{ x[0] }}">{{ x[2] }}</a>
            </div>
            <div class="divTableCell cell-tc30">
              {{ x[1] }}
            </div>
          </div>
        </ng-container>
        <div class="divTableRow">
          <div class="divTableCell cell-tc30">
            <img class="inline40" src="assets/Etc/nyang.png" />
          </div>
          <div class="divTableCell cell-tc40">
            냥
          </div>
          <div class="divTableCell cell-tc30">
            {{ gladia.PromotionNyang }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
