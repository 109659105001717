<div class="mat-elevation-z8 basecanvas">
<div class="section group">
  <div class="col span_2_of_2"><h1 class="subtitle">{{boss.Name}}</h1><hr class="hr90"></div>
</div>

<div class="section group">
  <div class="col span_2_of_2">
  <app-agent-motion></app-agent-motion>
  </div>
</div>

</div>