<nav mat-tab-nav-bar mat-align-tabs="center" color=warn>
  <a mat-tab-link routerLink="/" routerLinkActive #homeTab="routerLinkActive">Home</a>
  <a mat-tab-link routerLink="/agents" routerLinkActive #agentsTab="routerLinkActive" [active]="agentsTab.isActive">요원</a>
<!--  <a mat-tab-link routerLink="/boss" routerLinkActive #bossTab="routerLinkActive" [active]="bossTab.isActive">보스</a> -->
  <a mat-tab-link routerLink="/materials" routerLinkActive #materialsTab="routerLinkActive" [active]="materialsTab.isActive">소재</a>
  <a mat-tab-link routerLink="/equip" routerLinkActive #equipTab="routerLinkActive" [active]="equipTab.isActive">장비</a>
  <a mat-tab-link routerLink="/quest" routerLinkActive #questTab="routerLinkActive" [active]="questTab.isActive">퀘스트</a>
  <a mat-tab-link routerLink="/gladia" routerLinkActive #gladiaTab="routerLinkActive" [active]="gladiaTab.isActive">글라디아</a>
  <a mat-tab-link routerLink="/cocoon" routerLinkActive #cocoonTab="routerLinkActive" [active]="cocoonTab.isActive">고치</a>
  <a mat-tab-link routerLink="/etc" routerLinkActive #etcTab="routerLinkActive" [active]="etcTab.isActive">계산기</a>
</nav>
<router-outlet></router-outlet>



