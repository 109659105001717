<div class="mat-elevation-z8 basecanvas">
  <div class="section group">
    <div class="col span_2_of_2">
      <h1 class="subtitle">{{ data.Name }}</h1>
      <hr class="hr90" />
    </div>
  </div>

  <div class="section group">
    <div class="col span_1_of_2" style="text-align: center;">
      <div>
        <img
          src="assets/ItemsMaterials/{{ data.ID | underbarEliminator }}.jpg"
        />
      </div>
      <hr class="hr90" />
      <div class="divTable">
        <div class="divTableRow">
          <div class="divTableCell cell-tc40">티어</div>
          <div class="divTableCell cell-tc60">{{ data.Tier }}</div>
        </div>
      </div>
      <hr class="hr90" />
      <div class="divTable">
        <div class="divTableRow">
          <div class="divTableCell cell-tc40">파는가격</div>
          <div class="divTableCell cell-tc60">
            <img src="assets/Etc/nyang.png" class="inline15" /> {{ data.Sell }}
          </div>
        </div>
      </div>
      <hr class="hr90" />
      <div class="divTable">
        <div class="divTableRow">
          <div class="divTableCell cell-tc40">사는가격</div>
          <div class="divTableCell cell-tc60">
            <ng-container *ngIf="data.Buy[0] == 's'">
              <img src="assets/Etc/sphere.png" class="inline15" />
              {{ data.Buy.slice(1, data.Buy.length) }}
            </ng-container>
            <ng-container *ngIf="data.Buy[0] !== 's'">
              <img src="assets/Etc/nyang.png" class="inline15" />
              {{ data.Buy }}
            </ng-container>
          </div>
        </div>
      </div>
      <hr class="hr90" />
    </div>
    <div class="col span_1_of_2" style="white-space: pre-wrap;">
      <br />{{ data.Desc }}
    </div>
  </div>

  <div class="section group">
    <div class="col span_1_of_2">
      <h2 class="subtitle">랜덤드랍</h2>
      <hr class="hr90" />
      <div class="divTable" *ngIf="data.DropRandom">
        <div class="divTableRow" *ngFor="let x of getRandomDrop()">
          <div class="divTableCell">
          <a class="highlight" routerLink="/quest/{{ x[1]}}">{{
            x[0]
          }}</a
          ><br /><br />
          </div>
        </div>
      </div>
    </div>

    <div class="col span_1_of_2">
      <h2 class="subtitle">고정드랍</h2>
      <hr class="hr90" />
      <div class="divTable" *ngIf="data.DropFixed">
        <div class="divTableRow" *ngFor="let x of getFixedDrop()">
          <div class="divTableCell">
          <a class="highlight" routerLink="/quest/{{ x[2]}}">{{
            x[0]
          }}</a
          >: <b>{{ x[1] }}</b> <br /><br />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
