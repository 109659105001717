export const equiplist = [
  'EQ00001',
  'EQ00002',
  'EQ00003',
  'EQ00004',
  'EQ00005',
  'EQ00006',
  'EQ00007',
  'EQ00008',
  'EQ00009',
  'EQ00010',
  'EQ00011',
  'EQ00012',
  'EQ00013',
  'EQ00014',
  'EQ00015',
  'EQ00016',
  'EQ00017',
  'EQ00018',
  'EQ00019',
  'EQ00020',
  'EQ00021',
  'EQ00022',
  'EQ00023',
  'EQ00024',
  'EQ00025',
  'EQ00026',
  'EQ00027',
  'EQ00028',
  'EQ00034',
  'EQ00035',
  'EQ00036',
  'EQ00037',
  'EQ00038',
  'EQ00095',
  'EQ00096',
  'EQ00097',
  'EQ00098',
  'EQ00111',
  'EQ00112',
  'EQ00113',
  'EQ00114',
  'EQ00115',
  'EQ00116',
  'EQ00117',
  'EQ00118',
  'EQ00119',
  'EQ00120',
  'EQ00121',
  'EQ00122',
  'EQ00123',
  'EQ00124',
  'EQ00125',
  'EQ00128',
  'EQ00129',
  'EQ00132',
  'EQ00133',
  'EQ00135',
  'EQ00136',
  'EQ00139',
  'EQ00140',
  'EQ00141',
  'EQ00142',
  'EQ00180',
  'EQ00185',
  'EQ00186',
  'EQ00187',
  'EQ00188',
  'EQ00189',
  'EQ00190',
  'EQ00191',
  'EQ00192',
  'EQ00193',
  'EQ00194',
  'EQ00195',
  'EQ00196',
  'EQ00209',
  'EQ00210',
  'EQ00211',
  'EQ00212',
  'EQ00213',
  'EQ00214',
  'EQ00215',
  'EQ00216',
  'EQ00217',
  'EQ00218',
  'EQ00219',
  'EQ00223',
  'EQ00227',
  'EQ00228',
  'EQ00229',
  'EQ00232',
  'EQ00239',
  'EQ00240',
  'EQ00241',
  'EQ00242',
  'EQ00245',
  'EQ00248',
  'EQ00249',
  'EQ00251',
  'EQ00252',
  'EQ00253',
  'EQ00254',
  'EQ00255',
  'EQ00256',
  'EQ00259',
  'EQ00262',
  'EQ00263',
  'EQ00264',
  'EQ00265',
  'EQ00266',
  'EQ00267',
  'EQ00268',
  'EQ00269',
  'EQ00270',
  'EQ00271',
  'EQ00275',
  'EQ00279',
  'EQ00280',
  'EQ00281',
  'EQ00283',
  'EQ00284',
  'EQ00285',
  'EQ00286',
  'EQ00290',
  'EQ00294',
  'EQ00296',
  'EQ00298',
  'EQ00302',
  'EQ00306',
  'EQ00307',
  'EQ00308',
  'EQ00309',
  'EQ00310',
  'EQ00311',
  'EQ00312',
  'EQ00313',
  'EQ00314',
  'EQ00315',
  'EQ00316',
  'EQ00317',
  'EQ00318',
  'EQ00319',
  'EQ00320',
  'EQ00321',
  'EQ00325',
  'EQ00329',
  'EQ00331',
  'EQ00335',
  'EQ00339',
  'EQ00340',
  'EQ00341',
  'EQ00342',
  'EQ00346',
  'EQ00350',
  'EQ00351',
  'EQ00352',
  'EQ00354',
  'EQ00355',
  'EQ00356',
  'EQ00357',
  'EQ00362',
  'EQ00363',
  'EQ00364',
  'EQ00365',
  'EQ00366',
  'EQ00367',
  'EQ00368',
  'EQ00369',
  'EQ00373',
  'EQ00380',
  'EQ00383',
  'EQ00385',
  'EQ00386',
  'EQ00388',
  'EQ00389',
  'EQ00390',
  'EQ00391',
  'EQ00392',
  'EQ00394',
  'EQ00396',
  'EQ00406',
  'EQ00407',
  'EQ00409',
  'EQ00412',
  'EQ00418',
  'EQ00421',
  'EQ00422',
  'EQ00423',
  'EQ00426',
  'EQ00428',
  'EQ00432',
  'EQ00436',
  'EQ90001',
  'EQ90002',
  'EQ90003',
  'EQ90010',
  'EQ90011',
  'EQ90012',
  'EQ90013',
  'EQ90014',
  'EQ90015',
  'EQ90016',
  'EQ90017',
  'EQ90018',
  'EQ90019',
  'EQ90020',
  'EQ90021',
  'EQ90022',
  'EQ90023',
  'EQ90024',
  'EQ90025',
  'EQ90026',
  'EQ90027',
  'EQ90028',
  'EQ90029',
  'EQ90030',
  'EQ90031',
  'EQ90032',
  'EQ90033',
  'EQ90034',
  'EQ90035',
  'EQ90036',
  'EQ90037',
  'EQ90038',
  'EQ90039',
  'EQ90040',
  'EQ90041',
  'EQ90042',
  'EQ90043',
  'EQ90044',
  'EQ90045',
  'EQ90046',
  'EQ90047',
  'EQ90048',
  'EQ90049',
  'EQ90050',
  'EQ90051',
  'EQ90052',
  'EQ90053',
];
